.app {
}
.top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
html {
  /* background-color: #141414; */
}
.mt20 {
  margin-top: 60px;
}
.mt10 {
  margin-top: 40px;
}
.mt4 {
  margin-top: 10px;
}
img {
  width: 80%;
}
.m20 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(92, 91, 91);
}

.btn {
  height: 40px;
  border-radius: 40px;
  width: 180px;
  background-color: #00da5a;
  border: 1px solid #00da5a;
  color: #fff;
  font-size: 18px;
}
.fontWhite {
  color: #fff;
  opacity: 0.8;
}
.bar {
  margin-top: 10px;
}
.url {
  margin-top: 10px;
  font-weight: bold;
  color: #fff;
  font-size: 24px;
}
html {
  background-color: #000;
}
h1 {
  color: #fff;
  font-size: 24px;
}
.font12 {
  color: #ffffffcc;
  font-size: 14px;
  padding: 0 20px;
}

.phone {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.75rem;
  margin-bottom: 1.25rem;
  display: block;
  width: 100px;
  height: 212px;
}
.font2 {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #ffc1a2;
}
.logo {
  width: 180px;
  margin-bottom: 20px;
}
.pb10 {
  padding-bottom: 40px;
}
.tv {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.75rem;
  margin-bottom: 1.25rem;
  display: block;
  width: 300px;
  height: 212px;
}
.gap {
  display: grid;
  gap: 10px;
  margin-bottom: 20px;
}
.font3 {
  color: #fff;
}
.carousel {
  width: 100vw;
}
.mt6 {
  margin-top: 10px;
}
.font4 {
  color: #fff;
  font-size: 20px;
  margin: 20px 0;
}
.back {
  background-color: #000;
  border: none;
  color: #fff;
  margin: 20px;
  margin-bottom: 0px;
  height: 30px;
  border-radius: 4px;
  font-size: 20px;
}
